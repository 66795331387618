<template>
  <Header :user="loginJudge"></Header>
  <div class="clause">
    <h1>条款及细则</h1>
    <h4>
      本服务条款（以下称“条款”）描述了适用于您使用LostElk网站，服务和移动应用程序（统称为“服务”）的权利和责任，这些网站，服务和移动应用程序均由LostElk
      拥有和运营。
    </h4>
    <h4>
      在使用服务之前，请仔细阅读条款。如果您不同意本条款以及LostElk的隐私权政策（以下简称“隐私权政策”）和LostElk的社区准则（以下简称“社区准则”），则不得使用该服务。如果您代表公司或其他法人实体签署本条款，则表示您有权将该实体约束到本条款。如果您没有此类权限，则不得接受该条款或代表该实体使用服务。仅当您已经在您的居住辖区中达到成年年龄并且完全有能力并有能力签署，遵守并遵守本条款时，您才能使用该服务。
    </h4>
    <h4>1.帐户和注册</h4>
    <p>
      使用服务时，您可以注册一个帐户。我们建议您注册一个帐户，因为在LostElk上创建一个帐户可让您访问有关上传，下载和为社区做出贡献的特殊功能。如果注册账户，我们可能要求您在注册过程中向我们提供一些有关您自己的信息，其中一些信息是注册帐户所必需的。您需要保证提供的所有信息都是准确的，并且将来会保持准确和最新。我们还要求您提供密码以保护您的帐户安全。您有责任确保密码安全和机密。您帐户下发生的任何活动均由您负责。如果您的账户丢失，您可以通过发送邮件(32030954@qq.com)向社区管理员申请找回。
    </p>
    <h4>2.照片许可</h4>
    <p>
      A.通过该服务，您可以上传和发布照片以及书面文字，图像，Web链接，位置信息和其他内容（“用户内容”）。您在服务上提供的任何用户内容将属于您或您的许可方。我们不会要求您拥有用户内容的任何所有权-100%完全是您的。
    </p>
    <p>
      B.您对用户内容以及由于在服务上上传或发布用户内容而导致的任何后果负全部责任。每次您上载或发布发布的用户内容时，您都向我们声明并保证：
      您是用户内容的创建者和所有者，或拥有其他人或公司的所有必要权利，可以使用并允许其他用户使用您在本第6节中设想的服务上的用户内容；和
      您的用户内容（包括您，我们或服务的其他用户所使用的内容）不会并且不会侵犯或盗用任何第三方权利，包括版权和其他知识产权，隐私权，公开权或精神权利，或诽谤，诽谤或诽谤任何人。换句话说，您的用户内容必须是您的原创作品，并且在将用户内容上载或发布到服务之前，您必须获得对用户内容具有任何权利的任何第三方的许可。
    </p>
    <p>
      C.有限许可给我们。您授予我们一项全球性的，非排他性的，免版税的许可（具有分许可的权利），以托管，存储，转让，展示，改编，执行，复制，修改，翻译和分发您的用户内容（全部或以其他方式）部分）以任何媒体格式并通过任何媒体渠道（已知或以后开发）。您需要了解，我们不会为使用您的照片而向您付款，并且在不提供您署名或赔偿的情况下，您的照片将公开提供给公众使用。
    </p>
    <h4>3.免责声明</h4>
    <p>
      我们没有合理的方法来监视上传到服务或在服务上发布的所有用户内容，并且我们也不承担您或其他用户监视，编辑或控制您和其他用户的内容的义务。用户上传或发布到服务。这意味着我们对服务上的任何用户内容概不负责，并且您同意不因用户内容对我们提出任何索赔。也就是说，出于任何原因，包括我们认为用户内容违反了本条款或在其他方面令人反感，我们可能随时（不先通知您）从服务中删除，编辑，屏蔽或阻止任何用户内容。使用服务时，您将接触到其他用户的用户内容，其中某些内容可能令人反感，不准确或不雅。
    </p>
    <h4>本本服务条款的最新更新时间为2021年4月28日。</h4>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import Header from '../components/header/HeaderBox.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Header,
  },
  props: {
    cardColumn: Number,
  },
  setup() {
    const store = useStore();

    // 进行登陆判断
    const loginJudge = computed(() => {
      return store.state.user;
    });

    return {
      loginJudge,
    };
  },
});
</script>

<style scoped>
@import '../style/less/viewsStyle/clause.css';
</style>
